import { ChakraProvider } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";

import { AppPropsWithLayout } from "@/interfaces/common";
import { theme } from "@/theme/styles";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <motion.div
          key={router.route}
          initial="initial"
          animate="animate"
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
          }}
        >
          {getLayout(<Component {...pageProps} />)}
        </motion.div>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
